import {MultiMap} from 'mnemonist'
import {Bookmark, BranchId, EmptyObjectHash, Flashlet, Hash} from './flash-repo-domain'
import {last} from '@peachy/utility-kit-pure'

import {IFlashDb} from './IFlashDb'


export class MemFlashDb implements IFlashDb {

    // 'tables'
    private flashlets: Flashlet[] = []
    private branches: MultiMap<BranchId, Hash> = new MultiMap()

    //
    protected cache: MultiMap<Hash, Flashlet> = new MultiMap()


    constructor(private name: string = 'unnamed') {
    }


    async getBranchHistory(branchId: BranchId): Promise<Hash[]> {
        return [...this.branches.get(branchId) ?? []]
    }

    async getBookmark(): Promise<Bookmark> {
        return this.cache.size
    }

    async getBranchRootHash(branchId: BranchId): Promise<Hash> {
        // brave
        return last(this.branches.get(branchId)) ?? EmptyObjectHash
    }

    async getBranchIds(): Promise<BranchId[]> {
        return [...this.branches.keys()]
    }

    async pushBranchRootHash(branchId: BranchId, newRoot: Hash, oldRoot?: Hash) {

        const currentRoot = await this.getBranchRootHash(branchId)

        if (currentRoot === newRoot) {
            return false
        }

        if (oldRoot && oldRoot !== currentRoot) {
            console.log({oldRoot, currentRoot})
            throw `Stale branch ${branchId}`
        }

        this.branches.set(branchId, newRoot)
        return true
    }


    async hasHash(hash: Hash): Promise<boolean> {
        return this.cache.has(hash)
    }


    async getFlashlets(hash: Hash): Promise<Flashlet[]> {
        return this.cache.get(hash)
    }

    async getSlice(since: Bookmark): Promise<Flashlet[]> {

        console.log('getSlice MemFlash')

        return this.flashlets.slice(since)
    }

    async putSlice(slice: Flashlet[]): Promise<Bookmark> {

        const sliceCache: MultiMap<Hash, Flashlet> = new MultiMap()

        slice?.forEach((flashlet) => {
            if (!this.cache.has(flashlet.hash)) {
                sliceCache.set(flashlet.hash, flashlet)
                this.flashlets.push(flashlet)
            }
        })

        sliceCache.forEach((flashlet, hash) => {
            this.cache.set(hash, flashlet)
        })

        return this.cache.size
    }


    async dispose() {
        return new Promise<void>((resolve, reject) => {
            setImmediate(() => {
                this.cache.clear()
                this.branches.clear()
                this.flashlets = []
                resolve()
            })
        })
    }

    getName() {
        return this.name
    }


    dump() {

        console.log('Dumping', this.flashlets.length)

        this.flashlets.forEach(f => {
            console.log(f)
        })
    }

}
