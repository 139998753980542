import {MrLife, MrLifeBenefit, MrPolicy, MrSubscription} from '@peachy/core-domain-pure'
import {values} from '@peachy/utility-kit-pure'
import { Alteration } from '../src/alteration-kit/alteration-types'


export function expectAllSubscriptionStartDatesToBe(subscription: MrSubscription, expectedDate: number) {
    expect(subscription.startDate).toBe(expectedDate)
    values(subscription.policies).forEach(policy => expectAllPolicyStartDatesToBe(policy, expectedDate))
}

export function expectAllPolicyStartDatesToBe(policy: MrPolicy, expectedDate: number) {
    expect(policy.startDate).toBe(expectedDate)
    values(policy.lives).forEach(life => expectAllLifeStartDatesToBe(life, expectedDate))
}

export function expectAllLifeStartDatesToBe(life: MrLife, expectedDate: number) {
    expect(life.startDate).toBe(expectedDate)
    values(life.benefits).forEach(benefit => expectAllBenefitStartDatesToBe(benefit, expectedDate))
}

export function expectAllBenefitStartDatesToBe(benefit: MrLifeBenefit, expectedDate: number) {
    expect(benefit.startDate).toBe(expectedDate)
    expect(benefit.effectiveDate).toBe(expectedDate)
    expect(benefit.moriDate).toBe(expectedDate)
}

export function expectAllSubscriptionEndDatesToBe(subscription: MrSubscription, expectedDate: number) {
    expect(subscription.endDate).toBe(expectedDate)
    values(subscription.policies).forEach(policy => expectAllPolicyEndDatesToBe(policy, expectedDate))
}

export function expectAllPolicyEndDatesToBe(policy: MrPolicy, expectedDate: number) {
    expect(policy.endDate).toBe(expectedDate)
    values(policy.lives).forEach(life => expectAllLifeEndDatesToBe(life, expectedDate))
}

export function expectAllLifeEndDatesToBe(life: MrLife, expectedDate: number) {
    expect(life.endDate).toBe(expectedDate)
    values(life.benefits).forEach(benefit => expectBenefitEndDateToBe(benefit, expectedDate))
}

export function expectBenefitEndDateToBe(benefit: MrLifeBenefit, expectedDate: number) {
    expect(benefit.endDate).toBe(expectedDate)
}

export function expectAllSubscriptionItemsToBeCancelled(subscription: MrSubscription) {
    expect(subscription.status).toBe('CANCELLED')
    values(subscription.policies).forEach(policy => expectAllPolicyItemsToBeCancelled(policy))
}

export function expectAllPolicyItemsToBeCancelled(policy: MrPolicy) {
    expect(policy.status).toBe('CANCELLED')
    values(policy.lives).forEach(life => expectAllLifeItemsToBeCancelled(life))
}

export function expectAllLifeItemsToBeCancelled(life: MrLife) {
    expect(life.status).toBe('CANCELLED')
    values(life.benefits).forEach(benefit => expectBenefitToBeCancelled(benefit))
}

export function expectBenefitToBeCancelled(benefit: MrLifeBenefit) {
    expect(benefit.status).toBe('CANCELLED')
}

export function expectAlterationsToMatch(receivedAlterations: Alteration[], expectedAlterations: Alteration[]) {
    expect(receivedAlterations.length).toEqual(expectedAlterations.length)
    for (const expectedAlteration of expectedAlterations) {
        expect(receivedAlterations).toContainEqual(expectedAlteration)
    }
}