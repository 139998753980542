import {Page, PagePrimitive, PageValue} from '../primatives/page-primatives'
import {Key, KeyPath} from '../primatives/graph-primitives'
import {RepoHash} from '../primatives/hash-primatives'

export type PathScan = {
    pageKey: RepoHash,
    propKey: Key
    value: PageValue
}[]


export interface IPageStore {

    store(node: unknown): Promise<RepoHash>

    fetch<T = unknown>(key: RepoHash): Promise<T | null>


    storeAt(key: RepoHash, path: KeyPath, value: unknown): Promise<RepoHash>


    fetchAt<T = unknown>(key: RepoHash, path: KeyPath): Promise<T | null>

    fetchPage(key: RepoHash): Promise<Page | null>

    fetchPageAt(rootPageKey: RepoHash, path: KeyPath): Promise<Page | PagePrimitive>

    scanPath(rootPageKey: RepoHash, path: KeyPath): Promise<PathScan>
}
