import {
    HasLifecycleStatus,
    isCancelled,
    LifecycleStatus,
    MrLife,
    MrPlan,
    MrPolicy,
    MrSubscription,
    MrSubscriptionQuoteRequest,
} from '@peachy/core-domain-pure'
import {formatISO} from 'date-fns'
import {format} from 'date-fns-tz'
import {
    Draft,
    dump,
    first,
    isArray,
    isNullish,
    isNumber,
    isObject,
    isUndefined,
    KeyMapped,
    keys,
    mapById,
    pick,
    values
} from '@peachy/utility-kit-pure'
import {isInteger} from 'mathjs'

export function applyCancellationStatus<T extends HasLifecycleStatus>(
    item: T,
    effectiveDate: number,
    cancellationReason: string
): T {
    item.status = 'CANCELLED'
    item.endDate = earliestOf(item.endDate, effectiveDate)
    item.cancellationReason = cancellationReason
    return item
}

export function filterStatus<A extends { status?: A['status'] }>(a: A[], status: A['status']): A[] {
    return a.filter(i => i.status === status)
}


export function assertKeyMappedItemExists<T>(keyMappedItems: KeyMapped<T>, id: string, keyMapType: string, itemType: string) {
    const item = keyMappedItems[id]
    if (!item) throw `${itemType} ${id} not found in ${keyMapType} [${keys(keyMappedItems)}]`
    return item
}

export function assertKeyMappedItemDoesNotExist<T>(keyMappedItems: KeyMapped<T>, id: string, keyMapType: string, itemType: string) {
    const item = keyMappedItems[id]
    if (item) throw `${itemType} ${id} already exists in ${keyMapType} [${keys(keyMappedItems)}]`
}


export function earliestOf(...dates: number[]) {
    return Math.min(...dates.filter(d => !isNullish(d)))
}

export function latestOf(...dates: number[]) {
    return Math.max(...dates.filter(d => !isNullish(d)))
}


export function firstFrom<V, K extends string>(keyMapped: KeyMapped<V, K>) {
    return first(values(keyMapped))
}


// dirty logging helpers

export function looksLikeTimestamp(v: any): v is number {
    return v && isNumber(v) && isInteger(v) && (v.toString().length == 12 || v.toString().length == 13)
}


export function toJsonWithDateFormat(o: any, formatString?: string) {
    return JSON.stringify(o, (key, value) => {
        if (looksLikeTimestamp(value)) {
            if (formatString) {
                return format(value, formatString)
            }
            return formatISO(value)
        }
        return value
    }, 2)
}

export function readableDate(date: number) {
    return format(date, 'dd/MM/yyyy HH:mm')
}


export function dumpWithReadableDates(o: any) {
    console.log(toJsonWithDateFormat(o, 'dd/MM/yyyy HH:mm'))
}

export function dumpPolicySummary(policy: MrPolicy) {
    dumpWithReadableDates(policySummary(policy))
}


export function policySummary(policy: MrPolicy) {
    const picked = pick(policy, 'id', 'status', 'startDate', 'endDate', 'totalMonthlyPremium')

    return {
        ...picked,
        lives: mapById(values(policy.lives).map(lifeSummary))
    }
}

export function lifeSummary(life: MrLife) {
    return pick(life,
        'id',
        'type',
        'status',
        'planId',
        'startDate',
        'endDate',
        'benefits',
        'totalMonthlyPremium',
    )
}





export function isSmeQuote(subQuote: Draft<MrSubscriptionQuoteRequest>) {
    return subQuote.account?.type === 'COMPANY'
}

export type MinimalSubscription = {
    subscriptionTotal?: number,
    policies: {
        policyTotal?: number
        status: LifecycleStatus
        lives: {
            email: string
            lifeTotal?: number
            status: LifecycleStatus
            benefits: {
                id: string
                premium?: number
                status: LifecycleStatus
            }[]
        }[]
    }[]
}


export function getMinimalSubscription(subscription: MrSubscription): MinimalSubscription {
    return {
        subscriptionTotal: subscription.totalMonthlyPremium,
        policies: values(subscription.policies).map(p => {
            return {
                policyTotal: p.totalMonthlyPremium,
                status: p.status,
                lives: values(p.lives).map(l => {
                    return {
                        email: l.email,
                        lifeTotal: l.totalMonthlyPremium,
                        status: l.status,
                        benefits: values(l.benefits).map(b => {
                            return {
                                id: b.id,
                                premium: b.premium?.total ?? 0,
                                status: b.status,
                                groupSize: b.groupSize
                            }
                        })
                    }
                })
            }
        })
    }
}


export function dumpPremiums(quotedSub: MrSubscription, message: string) {
    const toDump = getMinimalSubscription(quotedSub)
    dump(toDump, message)
}


export function pruneCancelledPolicies(subscription: MrSubscription): MrSubscription {
    return {
        ...subscription,
        policies: mapById(values(subscription.policies)
            .filter(policy => !isCancelled(policy))
            .map(policy => pruneCancelledLives(policy))
        )
    }
}

export function pruneCancelledLives(policy: MrPolicy): MrPolicy {
    return {
        ...policy,
        lives: mapById(values(policy.lives)
            .filter(life => !isCancelled(life))
            .map(life => pruneCancelledBenefits(life))
        )
    }
}

export function pruneCancelledBenefits(life: MrLife): MrLife {
    return {
        ...life,
        benefits: mapById(values(life.benefits)
            .filter(benefit => !isCancelled(benefit))
        )
    }
}


export function filterPlansForPolicy(policy: MrPolicy, plans: MrPlan[]) {
    const policyPlanIds = values(policy.lives).map(life => life.planId)
    return plans.filter(plan => policyPlanIds.includes(plan.id))
}


export function pruneUndefined(x: unknown): void {
    if (isObject(x)) {
        Object.keys(x).forEach(k => {
            if (isUndefined(x[k])) {
                delete x[k]
            } else {
                pruneUndefined(x[k])
            }
        })
    } else if (isArray(x)) {
        x.forEach((e, i) => {
            if (isUndefined(e)) {
                x[i] = null
            } else {
                pruneUndefined(x[i])
            }
        })
    }
}



export function logPolicyStatuses(subscription: MrSubscription) {
    for (const policy of values(subscription.policies)) {
        console.log(`Policy ${policy.id} is ${policy.status}`)
    }
}
