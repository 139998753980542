import {createSignal} from 'solid-js'
import {Tabs} from '../components/Tabs/Tabs'
import styles from './Scratch.module.css'


type Tabs = 'ONE'|"TWO"



export function Scratch() {

    const currentTabSignal = createSignal<Tabs>('ONE')

    return (
        <div class={styles.Tabs}>

            <Tabs currentTab={currentTabSignal} >{() => ({
                'ONE': <p>ONE</p>,
                'TWO': <p>TWO</p>,
            })}</Tabs>

        </div>
    )
}
