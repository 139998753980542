import {
    Address,
    LifeType,
    MrLife,
    MrLifeBenefit,
    MrPlan,
    MrPlanBenefit,
    MrPolicy,
    MrSubscription,
    Premium,
    UkRegions
} from '@peachy/core-domain-pure'
import {mapById, newUUID, values} from '@peachy/utility-kit-pure'
import {QuoteParams} from '@peachy/quote-pure'

export const defaultStartDate = new Date('2020/01/05').getTime()
export const oneMonthAfterStartDate = new Date('2020/02/05').getTime()
export const twoMonthsAfterStartDate = new Date('2020/03/05').getTime()
export const effectiveDate = Date.now()
export const futureDate = new Date('2030/01/01').getTime()

export const defaultDateOfBirth = new Date('1990/05/05').getTime()
export const olderDateOfBirth = new Date('1980/05/05').getTime()
export const youngerDateOfBirth = new Date('2000/05/05').getTime()

export const lowerExcess = 200_00
export const defaultExcess = 500_00
export const higherExcess = 1000_00

export const plan1Id = 'plan1'
export const plan2Id = 'plan2'



export function getSubscription(overrides?: Partial<MrSubscription>): MrSubscription {
    return {...{
            id: newUUID(),
            billingAnchor: defaultStartDate,
            contactEmail: 'BossMan@BigCo.com',
            contactName: 'Boss Man',
            name: 'MySubscription',
            plans: mapById([getPlan({id: plan1Id}), getPlan({id: plan2Id})]),
            policies: mapById([
                getPolicy({lives: mapById([getLife({planId: plan1Id}), getLife({planId: plan1Id})])}),
                getPolicy({lives: mapById([getLife({planId: plan2Id}), getLife({planId: plan2Id})])})
            ]),
            startDate: defaultStartDate,
            status: 'ACTIVE',
            timezone: 'Europe/London',
            versionIdx: 1
        }, ...overrides}
}

export function getPolicy(overrides?: Partial<MrPolicy>): MrPolicy {
    return {...{
            id: newUUID(),
            lives: mapById([getLife()]),
            startDate: defaultStartDate,
            status: 'ACTIVE',
            version: 0
        }, ...overrides}
}

export function getPlan(overrides?: Partial<MrPlan>): MrPlan {
    return {...{
            id: plan1Id,
            benefits: mapById(defaultPlanBenefits()),
            name: 'MyPlan'
        }, ...overrides}
}

export function getLife(overrides?: Partial<MrLife>): MrLife {
    return {...{
            id: newUUID(),
            address: {postcode: 'WD4 8AA', region: UkRegions.London} as Address,
            benefits: mapById(defaultLifeBenefits()),
            dateOfBirth: defaultDateOfBirth,
            firstname: 'Employee',
            lastname: 'Guy',
            postcode: 'WD4 8AA',
            startDate: defaultStartDate,
            status: 'ACTIVE',
            type: 'PRIMARY',
            smsMarketingAccepted: false,
            marketingAccepted: false,
            planId: getPlan().id
        }, ...overrides}
}

export function getLifeBenefit(overrides?: Partial<MrLifeBenefit>): MrLifeBenefit {
    return {...{
            id: 'VIRTUAL_GP',
            status: 'ACTIVE',
            premium: defaultPremium()
        }, ...defaultQuoteParams(), ...overrides}
}

export function defaultQuoteParams(): QuoteParams {
    return {
        effectiveDate: defaultStartDate,
        startDate: defaultStartDate,
        moriDate: defaultStartDate,
        renewalPeriodMonths: 12,
        rateSet: 'SME',
        groupSize: 10,
        quoteModelVersion: '2',
    }
}

export function defaultPlanBenefits(): MrPlanBenefit[] {
    return [
        {id: 'VIRTUAL_GP'},
        {id: 'ADVICE_COUNSELLING'},
        {id: 'CONSULTATIONS_DIAGNOSTICS', limit: 1000000, excess: defaultExcess},
        {id: 'MENTAL_HEALTH_IN'},
        {id: 'MENTAL_HEALTH_OUT'},
    ]
}

export function defaultLifeBenefits(): MrLifeBenefit[] {
    return [
        getLifeBenefit({id: 'VIRTUAL_GP'}),
        getLifeBenefit({id: 'ADVICE_COUNSELLING'}),
        getLifeBenefit({id: 'CONSULTATIONS_DIAGNOSTICS', limit: 1000000, excess: defaultExcess}),
        getLifeBenefit({id: 'MENTAL_HEALTH_IN'}),
        getLifeBenefit({id: 'MENTAL_HEALTH_OUT'}),
    ]
}

function defaultPremium(): Premium {
    return {
        adjustedForContingency: 100,
        adjustedForCorrelation: 100,
        adjustedForDiscounts: 100,
        adjustedForIpt: 100,
        adjustedForLossRatio: 100,
        adjustedForPriceResolution: 100,
        baseRiskCost: 100,
        total: 100
    }
}

export function overrideAllSubscriptionDatesToBe(subscription: MrSubscription, date: number) {
    subscription.startDate = date
    values(subscription.policies).forEach(policy => overrideAllPolicyDatesToBe(policy, date))
}

export function overrideAllPolicyDatesToBe(policy: MrPolicy, date: number) {
    policy.startDate = date
    values(policy.lives).forEach(life => overrideAllLifeDatesToBe(life, date))
}

export function overrideAllLifeDatesToBe(life: MrLife, date: number) {
    life.startDate = date
    values(life.benefits).forEach(benefit => overrideAllBenefitDatesToBe(benefit, date))
}

export function overrideAllBenefitDatesToBe(benefit: MrLifeBenefit, date: number) {
    benefit.startDate = date
    benefit.effectiveDate = date
    benefit.moriDate = date
}

export function clearAllBenefitParams(life: MrLife) {
    values(life.benefits).forEach(benefit => {
        life.benefits[benefit.id] = {} as MrLifeBenefit
    })
}

export function getPrimary(policy: MrPolicy): MrLife {
    return getLifeOfType(policy, 'PRIMARY')
}

export function getDependant(policy: MrPolicy): MrLife {
    return getLifeOfType(policy, 'DEPENDANT')
}

export function getLifeOfType(policy: MrPolicy, type: LifeType): MrLife {
    return values(policy.lives).find(life => life.type == type)
}
