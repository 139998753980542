import {MrLife, MrSubscription, toClass} from '@peachy/core-domain-pure'

import {reconcileLifeModelAlteration} from '../reconciliation/reconcileLifeModelAlteration'
import {ensureCancellationStatus} from '../reconciliation/valid-alterations/cancellation'
import {AlterationAttribution} from '../alteration-kit/alteration-types'

export class LifeAlterationAgent {

    private alteredLife: MrLife

    constructor(
        private currentLife: MrLife,
        private subscription: MrSubscription,
        private attribution: AlterationAttribution
    ) {
        this.subscription = toClass(subscription, MrSubscription)
        this.alteredLife = structuredClone(currentLife)
    }

    public alterLife(alteredLife: MrLife) {
        this.alteredLife = alteredLife
        this.reconcile(alteredLife.planId)
    }

    public cancelLife(cancellationReason: string) {
        ensureCancellationStatus(this.alteredLife, this.attribution.effectiveDate, cancellationReason)
        this.reconcile()
    }

    public alterBirthdate(birthdate: number) {
        this.alteredLife.dateOfBirth = birthdate
        this.reconcile()
    }


    public switchPlan(planId: string) {
        this.assertLifeHasNotPlan(planId)
        this.reconcile(planId)
    }

    public alterPlan(planId: string) {
        this.assertLifeHasPlan(planId)
        this.reconcile(planId)
    }

    private reconcile(planId?: string) {
        planId ??= this.alteredLife?.planId
        planId ??= this.currentLife.planId

        this.alteredLife = reconcileLifeModelAlteration(
            this.currentLife,
            this.alteredLife,
            this.subscription.resolvePlan(planId),
            this.attribution.effectiveDate
        )
    }

    public getAlteredLife() {
        return this.alteredLife
    }

    // ---------------------------------

    private assertLifeHasPlan(planId: string) {
        if (this.alteredLife.planId !== planId) {
            throw `Life ${this.alteredLife.id} does not have plan ${planId}. Life has plan ${this.alteredLife.planId}`
        }
    }

    private assertLifeHasNotPlan(planId: string) {
        if (this.alteredLife.planId === planId) {
            throw `Life ${this.alteredLife.id} already has plan ${planId}.`
        }
    }
}
