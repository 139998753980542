import {BranchInfo, BranchName, InstallationId, MergeState} from '../../primatives/repo-primatives'
import {BookmarkedPage, PageInfo, PageReceipt, PageSyncState} from '../../key-interfaces/IPageIo'
import {Observable} from 'rxjs'
import {Bookmark} from '../../primatives/page-primatives'
import {RepoHash} from '../../primatives/hash-primatives'
import {
    CommitBranchRequest,
    CommitMergeStateRequest,
    CommitPageSyncStateRequest,
    FetchBranchUpdatesSinceRequest,
    FetchMergeStateRequest,
    IRepoSyncApi
} from '../../sync-api-definition/sync-api-definition'
import {MemoRepoContext} from './MemoRepo'


export class RepoSyncAgent implements IRepoSyncApi {

    constructor(public repoContext: MemoRepoContext) {
    }

    commitBranch(request: CommitBranchRequest): Promise<BranchInfo> {
        return this.repoContext.branchIo.commitBranch(request.branch, request.branchHeadKey, request.commitMessage, request.expectedHeadKey)
    }

    commitMergeState(request: CommitMergeStateRequest): Promise<MergeState> {
        return this.repoContext.branchIo.commitMergeState(request.installationId, request.branchName, request.mergeState, request.expectedMergeState)
    }

    fetchBranch(branch: BranchName): Promise<BranchInfo> {
        return this.repoContext.branchIo.fetchBranch(branch)
    }

    fetchBranchUpdatesSince(request: FetchBranchUpdatesSinceRequest): Observable<BranchInfo> {
        return this.repoContext.branchIo.fetchBranchUpdatesSince(request.branch, request.rootBookmark)
    }

    fetchBranches(): Promise<BranchInfo[]> {
        return this.repoContext.branchIo.fetchBranches()
    }

    fetchMergeState(request: FetchMergeStateRequest): Promise<MergeState> {
        return this.repoContext.branchIo.fetchMergeState(request.installationId, request.branchName)
    }

    commitPageSyncState(request: CommitPageSyncStateRequest): Promise<PageSyncState> {
        return this.repoContext.pageIo.commitPageSyncState(
            request.remoteInstallationId,
            request.pageSyncState,
            request.expectedState
        )
    }





    // commitPages(pages: Observable<Page>): PageReceiptStream {
    //     return this.repoContext.pageIo.commitPages(pages)
    // }

    fetchBookmark(): Promise<Bookmark> {
        return this.repoContext.pageIo.fetchBookmark()
    }

    fetchPageKeysSince(bookmark?: Bookmark): Observable<BookmarkedPage> {
        return this.repoContext.pageIo.fetchPageKeysSince(bookmark)
    }

    fetchPageSyncState(remoteInstallationId: InstallationId): Promise<PageSyncState> {
        return this.repoContext.pageIo.fetchPageSyncState(remoteInstallationId)
    }

    commitPageInfoStream(pageInfo$: Observable<PageInfo>, skipExistenceCheck?: boolean): Observable<PageReceipt> {
        return this.repoContext.pageIo.commitPageInfoStream(pageInfo$, skipExistenceCheck)
    }

    fetchPageInfoStream(repoHash$: Observable<RepoHash>): Observable<PageInfo> {
        return this.repoContext.pageIo.fetchPageInfoStream(repoHash$)
    }

    filterForUnknownPageHashes(repoHash$: Observable<RepoHash>): Observable<RepoHash> {
        return this.repoContext.pageIo.filterForUnknownPageHashes(repoHash$)
    }

    // fetchPages(keys: PageHashStream): PageInfoStream {
    //     return this.repoContext.pageIo.fetchPages(keys)
    // }
    //
    // filterForUnknownPages(keys: Observable<RepoHash>): Observable<RepoHash> {
    //     return this.repoContext.pageIo.filterForUnknownHashes(keys)
    // }
}
