import {BookmarkedPage, IPageIo, PageInfo, PageReceipt, PageSyncState} from '../../key-interfaces/IPageIo'
import {PageIoMemory} from './PageIoMemory'
import {Bookmark, Page} from '../../primatives/page-primatives'
import {InstallationId} from '../../primatives/repo-primatives'
import {concatMap, map, Observable, of} from 'rxjs'
import {RepoHash} from '../../primatives/hash-primatives'
import {drain} from '@peachy/utility-kit-rxjs'

export class PageIoWorkspace implements IPageIo {

    workspace = new PageIoMemory()

    constructor(private basePageIo: IPageIo) {
    }

    filterForUnknownPages(pageInfo$: Observable<PageInfo>): Observable<PageInfo> {
        throw 'filterForUnknownPages not implemented for workspaces'
    }


    filterForUnknownPageHashes(repoHash$: Observable<RepoHash>): Observable<RepoHash> {
        throw 'filterForUnknownPageHashes not implemented for workspaces'
    }

    commitPageInfoStream(pageInfo$: Observable<PageInfo>, skipExistenceCheck?: boolean): Observable<PageReceipt> {
        return this.workspace.commitPageInfoStream(pageInfo$, skipExistenceCheck)
    }

    fetchPageInfoStream(repoHash$: Observable<RepoHash>): Observable<PageInfo> {
        const unknownPageHashes = this.workspace.filterForUnknownPageHashes(repoHash$)
        const fetchedPage$ = this.basePageIo.fetchPageInfoStream(unknownPageHashes)
        return of(this.workspace.commitPageInfoStream(fetchedPage$)).pipe(
            concatMap(fetchedPages => {
                return this.workspace.fetchPageInfoStream(repoHash$)
            })
        )
    }

    async commitWorkspace() {
        const keysToCommit = this.basePageIo.filterForUnknownPageHashes(
            this.workspace.fetchPageKeysSince().pipe(
                map(bm => bm.repoHash)
            )
        )
        return drain(
            this.basePageIo.commitPageInfoStream(this.workspace.fetchPageInfoStream(keysToCommit))
        )
    }



    async fetchPage(key: RepoHash): Promise<PageInfo> {
        const workspacePage = await this.workspace.fetchPage(key)
        return workspacePage ?? await this.basePageIo.fetchPage(key)
    }

    async commitPage(page: Page): Promise<PageReceipt> {
        return this.workspace.commitPage(page)
    }


    // todo impl later? Split interface?

    commitPageSyncState(remoteInstallationId: InstallationId, pageSyncState: PageSyncState, expectedState?: PageSyncState): Promise<PageSyncState> {
        throw 'commitPageSyncState not implemented for workspaces'
    }

    fetchBookmark(): Promise<Bookmark> {
        throw 'fetchBookmark not implemented for workspaces'
    }


    fetchPageKeysSince(bookmark?: Bookmark): Observable<BookmarkedPage> {
        throw 'fetchPageKeysSince not implemented for workspaces'
    }

    fetchPageSyncState(remoteInstallationId: InstallationId): Promise<PageSyncState> {
        throw 'fetchPageSyncState not implemented for workspaces'
    }

    async [Symbol.asyncDispose](): Promise<void> {
        this.basePageIo = null
    }

    async asyncDispose(): Promise<void> {
        this.basePageIo = null
    }
}
