import {MrPolicy, MrSubscription, toClass} from '@peachy/core-domain-pure'
import {AlterationAttribution} from '../alteration-kit/alteration-types'
import {ensureCancellationStatus} from '../reconciliation/valid-alterations/cancellation'
import {reconcilePolicyModelAlteration} from '../reconciliation/reconcilePolicyModelAlteration'
import {LifeAlterationAgent} from './LifeAlterationAgent'

export class PolicyAlterationAgent {

    private alteredPolicy: MrPolicy

    constructor(
        private currentPolicy: MrPolicy,
        private subscription: MrSubscription,
        private attribution: AlterationAttribution
    ) {
        this.subscription = toClass(subscription, MrSubscription)
        this.alteredPolicy = structuredClone(currentPolicy)
    }

    public withLifeAlterationAgent(lifeId: string, alter: (lifeAlterationAgent:LifeAlterationAgent) => void) {
        const life = toClass(this.alteredPolicy, MrPolicy).lives[lifeId]
        if (!life) throw `Life ${lifeId} does not exist on subscription ${this.subscription.id}`
        const lifeAlterationAgent = new LifeAlterationAgent(life, this.subscription, this.attribution)
        alter(lifeAlterationAgent)
        this.subscription.policies[this.alteredPolicy.id].lives[life.id] = lifeAlterationAgent.getAlteredLife()
        this.reconcile()
    }

    public alterPolicy(alteredPolicy: MrPolicy) {
        this.alteredPolicy = alteredPolicy
        this.reconcile()
    }

    public cancelPolicy(cancellationReason: string) {
        ensureCancellationStatus(this.alteredPolicy, this.attribution.effectiveDate, cancellationReason)
        this.reconcile()
    }

    private reconcile() {
        this.alteredPolicy = reconcilePolicyModelAlteration(
            this.currentPolicy,
            this.alteredPolicy,
            this.subscription.plans,
            this.attribution.effectiveDate
        )
    }

    public getAlteredPolicy() {
        return this.alteredPolicy
    }


}
