import {
    graphNode,
    KeyPath,
    KeyPathString,
    keyPathString,
    NodeActor,
    Transaction
} from '../../primatives/graph-primitives'
import {Observable, ReplaySubject} from 'rxjs'
import {RepoHash} from '../../primatives/hash-primatives'
import equal from 'fast-deep-equal/es6'
import {Page, PagePrimitive} from '../../primatives/page-primatives'
import {PageStore} from '../page/PageStore'
import {PageIoWorkspace} from '../page/PageIoWorkspace'

export class ReactiveNodeActor<ReadOnly extends boolean> implements NodeActor<ReadOnly> {

    private rootNodeHash: RepoHash
    private observers: SubjectMap = {}

    constructor(
        private store: PageStore,
        rootNodeHash: RepoHash,
        public readonly isReadOnly: ReadOnly,
        private onNewRootKey?: (key: RepoHash, commitMessage?: string) => Promise<void>,
    ) {
        this.rootNodeHash = rootNodeHash
    }

    getRootNodeHash() {
        return this.rootNodeHash
    }

    async getNodeValue<T>(path: KeyPath): Promise<T | null> {
        return this.store.fetchAt(this.rootNodeHash, path)
    }

    async getNodePage(path: KeyPath): Promise<Page | PagePrimitive> {
        return this.store.fetchPageAt(this.rootNodeHash, path)
    }

    async setNodeValue(path: KeyPath, value: unknown, commitMessage: string): Promise<RepoHash | null> {

        if (this.isReadOnly) throw new Error('node is readonly')

        const currentRootNodeKey = this.rootNodeHash

        const newRootKey = await this.store.storeAt(currentRootNodeKey, path, value)

        if (!newRootKey) return null

        if (currentRootNodeKey !== this.rootNodeHash) {
            throw new Error('Failed to set node as interim change detected')
        }

        if (!equal(this.rootNodeHash, newRootKey)) {
            this.rootNodeHash = newRootKey
            if (this.onNewRootKey) {
                await this.onNewRootKey(newRootKey, commitMessage)
            }
            this.notifyObservers(path)
        }
        return this.rootNodeHash
    }

    observeNode<T>(path: KeyPath): Observable<T> {
        const pathString = keyPathString(path)
        let subject = this.observers[pathString]
        if (!subject) {
            subject = (this.observers[pathString] = new ReplaySubject<T>(1))
            subject.next(graphNode(this, path))
        }
        return subject
    }

    async transactNode<T>(path: KeyPath, transaction: Transaction<T, ReadOnly>): Promise<void> {

        const pageIoWorkspace = new PageIoWorkspace(this.store.pageIo)
        const pageStoreWorkspace = new PageStore(pageIoWorkspace, this.store.pageCacheSize)

        // TODO why did I extract _transactionRootHash?
        let _transactionRootHash: RepoHash = this.rootNodeHash
        const commitMessages: string[] = []

        const workspaceNodeActor = new ReactiveNodeActor(
                pageStoreWorkspace,
                this.rootNodeHash,
                this.isReadOnly,
                async (newRootHash, commitMessage) => {
                    _transactionRootHash = newRootHash
                    if (commitMessage) commitMessages.push(commitMessage)
                }
            )

        const workspaceNode = graphNode<T, ReadOnly>(workspaceNodeActor, path)
        const transactionCommitMessage = await transaction(workspaceNode)
        if (!this.isReadOnly) {
            const squashedCommitMessage = [transactionCommitMessage, ...commitMessages.reverse()].join('\n')
            await pageIoWorkspace.commitWorkspace()
            await this.setNodeValue(path, await workspaceNodeActor.getNodePage(path), squashedCommitMessage)
        }
    }


    private notifyObservers(triggerPath: KeyPath) {
        const pathString = keyPathString(triggerPath)
        const allObservedPaths = Object.keys(this.observers)
        const relevantPaths = allObservedPaths
            .filter(observedPath => this.arePathsDependent(observedPath, pathString))
            .sort((a, b) => a.length - b.length)

        for (const pathString of relevantPaths) {
            const path: KeyPath = pathString.split(/[.[\]]/).filter(x => x !== '')

            const subject = this.observers[pathString]
            subject.next(graphNode(this, path))
        }
    }

    private arePathsDependent(pathA: string, pathB: string) {
        return pathA.startsWith(pathB) || pathB.startsWith(pathA)
    }
}


type PathMapped<T> = {
    [k: KeyPathString]: T
}

type SubjectMap = PathMapped<ReplaySubject<any>>
