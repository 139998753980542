import {IMemoRepo} from '../../key-interfaces/IMemoRepo'
import {IBranchIo} from '../../key-interfaces/IBranchIo'
import {graphNode, RepoNode} from '../../primatives/graph-primitives'
import {ReactiveNodeActor} from './ReactiveNodeActor'
import {assertNotNullish, dump, KeyMapped} from '@peachy/utility-kit-pure'
import {REPO_HASH_NULL, RepoHash} from '../../primatives/hash-primatives'

import {BranchName, InstallationId, MergeBias, RepoId} from '../../primatives/repo-primatives'
import {IPageIo} from '../../key-interfaces/IPageIo'

import {syncRepo} from './syncRepo'
import {RepoSyncAgent} from './RepoSyncAgent'
import {IRepoSyncApi} from '../../sync-api-definition/sync-api-definition'
import {AsyncDisposableResource} from '@peachy/utility-kit-disposable'
import {PageStore} from '../page/PageStore'
import {concatMap, from, map, toArray} from 'rxjs'


export type MemoRepoContext = {
    repoId: RepoId
    installationId: InstallationId
    branchIo: IBranchIo,
    pageIo: IPageIo,
    pageStore: PageStore
}


export const REPO_DEFAULT_BRANCH: BranchName = 'master'

export class MemoRepo<RootType = object> implements IMemoRepo<RootType>, AsyncDisposableResource {

    private branchRootCache: KeyMapped<RepoNode<any, boolean>> = {}
    constructor(public repoContext: MemoRepoContext) {
    }
    async fetchRootNode<T = RootType, ReadOnly extends boolean = false>(branch: BranchName = REPO_DEFAULT_BRANCH, readOnly?: ReadOnly): Promise<RepoNode<T, ReadOnly>> {

        let cachedRootNode = this.branchRootCache[branch]
        if (!cachedRootNode) {

            const branchInfo = await this.repoContext.branchIo.fetchBranch(branch)
            const rootKey = branchInfo?.branchHead ?? REPO_HASH_NULL

            // @ts-ignore
            cachedRootNode = this.branchRootCache[branch] = graphNode<T, ReadOnly>(
                new ReactiveNodeActor(
                    this.repoContext.pageStore,
                    rootKey,
                    readOnly,
                    async (newRootKey, commitMessage: string) => {
                        await this.repoContext.branchIo.commitBranch(branch, newRootKey, commitMessage)
                    }
                ))
        }

        return cachedRootNode as any
    }

    async fetchRootNodeVersionAtTimestamp<T = RootType>(timestamp: number, branch: BranchName = REPO_DEFAULT_BRANCH) {
        assertNotNullish(branch)
        assertNotNullish(timestamp)

        const branchInfo = await this.repoContext.branchIo.fetchBranchHeadAtTimestamp(branch, timestamp)
        if (!branchInfo) return null

        const rootKey = branchInfo.branchHead

        return graphNode<T, true>(
            new ReactiveNodeActor(
                this.repoContext.pageStore,
                rootKey,
                true,
                // async (newRootKey, commitMessage: string) => {
                //     await this.repoContext.branchIo.commitBranch(branch, newRootKey, commitMessage)
                // }
            ))
    }

    fetchBranchHistory(branch: BranchName = REPO_DEFAULT_BRANCH) {
        return this.repoContext.branchIo.fetchBranchUpdatesSince(branch).pipe(
            toArray(),
            map(a => a.reverse()),
            // todo - reverse options on fetchBranchUpdatesSince
            concatMap(a => from(a))
        )
    }

    async fetchNode<T = RootType>(key: RepoHash): Promise<RepoNode<T, true>> {
        assertNotNullish(key)
        return graphNode<T, true>(new ReactiveNodeActor(this.repoContext.pageStore, key, true))
    }

    async createBranch<T = RootType>(branch: BranchName, commitMessage: string, fromBranch?: BranchName): Promise<RepoNode<T>> {
        await this.repoContext.branchIo.createBranch(branch, commitMessage, fromBranch)
        return this.fetchRootNode(branch)
    }

    async createDefaultBranch<T = RootType>(commitMessage: string = 'Initial Chicken'): Promise<RepoNode<T, false>> {
        return this.createBranch(REPO_DEFAULT_BRANCH, commitMessage)
    }

    async deleteBranch(branch: BranchName): Promise<void> {
        await this.repoContext.branchIo.deleteBranch(branch)
    }

    async branchExists(branch: BranchName): Promise<boolean> {
        return this.repoContext.branchIo.branchExists(branch)
    }



    async sync(
        remoteSyncAgent: IRepoSyncApi,
        remoteInstallationId: InstallationId,
        branchName: BranchName,
        mergeBias: MergeBias
    ): Promise<void> {
        const thisSyncAgent = new RepoSyncAgent(this.repoContext)

        const branchHead = await syncRepo(thisSyncAgent, remoteSyncAgent, remoteInstallationId, branchName, mergeBias)
        const rootNode = await this.fetchRootNode(branchName)
        await rootNode(branchHead as RootType)


    }

    async dumpSyncState(remoteInstallationId: InstallationId, branch: string) {

        const pageSyncState = await this.repoContext.pageIo.fetchPageSyncState(remoteInstallationId)
        const mergeSyncState = await this.repoContext.branchIo.fetchMergeState(remoteInstallationId, branch)
        const branchHead = await this.repoContext.branchIo.fetchBranch(branch)
        const state = {
            pageSyncState,
            mergeSyncState,
            branchHead,
        }
        dump(state)
        return state
    }

    async asyncDispose(): Promise<void> {
        await this.repoContext.pageIo.asyncDispose()
        await this.repoContext.branchIo.asyncDispose()
    }

    async [Symbol.asyncDispose]() {
        await this.asyncDispose()
    }
}
